import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LogoIcon from "../../assets/images/logo-icon.png";
import Button from "../../shared_component/Button/Button";
import BottomTermPrivacy from "../../component/BottomTermPrivacy/BottomTermPrivacy";
import "./Login.css";
import Input from "../../shared_component/Input/Input";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth} from "../../firebase/firebase";
import { getUserInfo } from "../../firebase/database/user";
import { loginEvent, setUserIDAnalytics } from "../../firebase/analytics/analytics";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingText, setLoadingText] = useState({ show: false, text: "" });
  
  const navigate = useNavigate();
  const goto = (url) => {
    navigate(url);
  };

  useEffect(()=> {
    document.title = "Login | Berlayar AI";
  },[])

  const signUsingEmail = (data) => {
    const { email, password } = data;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        const userInfo = {
          uid: userCredentials.user.uid,
          email: userCredentials.user.email,
        };
        const result = await getUserInfo(userInfo.uid);
        localStorage.setItem("userInfo", JSON.stringify(result));
        const analyticsData = {
          uid:userInfo.uid,
          email:userInfo.email,
          method:'email'
        }
        setUserIDAnalytics(userInfo.uid);
        loginEvent(analyticsData);
        goto("/generate");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-login-credentials") {
          setLoadingText({
            show: true,
            text: "Login Invalid, Please enter correct Email and Password",
            danger:true
          });
        }
      });
  };

  const handleOnChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }

    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const submit = () => {
    
    if ((email === "") | (password === "")) {
      setLoadingText({ show: true, text: "Please fill all the form",danger:true});
    } else {
      setLoadingText({ show: true, text: "Loading ..." });
      const data = {
        email,
        password,
      };
      signUsingEmail(data);
    }
  };

  return (
    <div className="login-container">
      <div className="center-container">
        <div className="item-container">
          <img src={LogoIcon} alt="logo" />
          <p className="login-subtitle">Login to your account</p>

          <div className="login-form-container">
          <p style={{ 
              display: loadingText.show ? "block" : "none", 
              color:loadingText.danger?"#F4444C":"#8D8D8D", 
              textAlign:'center',
              width:'100%',
              fontFamily:'Outfit',
              marginBottom:'10px'
              }}>
              {loadingText.text}
            </p>
            <Input
              type="email"
              placeholder="Enter email addres"
              name={"email"}
              value={email}
              onChange={handleOnChange}
            />
            <br />
            <br />
            <Input
              type="password"
              placeholder="Enter password"
              name={"password"}
              value={password}
              onChange={handleOnChange}
            />
            <br />
            <br />
            <Button
              text="Login"
              primary={true}
              padding={"10px 20px"}
              width={"550px"}
              onClick={submit}
            />
            
          </div>
          <p className="sign-up">
            Do not have an account ?{" "}
            <span
              onClick={() => {
                goto("/sign-up");
              }}
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
      <BottomTermPrivacy />
    </div>
  );
}

export default Login;
