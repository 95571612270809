import axios from "axios";

const getTwitterLongLiveToken = (code) => {
    return new Promise(async(resolve, reject) => {

        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+'twitter/get-access-token',{code});
        resolve(result.data);
    })    
}

const getTwitterNewRefreshToken = (code) => {
    return new Promise(async(resolve, reject) => {

        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+'twitter/get-fresh-token',{code});
        resolve(result.data);
    })  
}

const twitterUploadMedia = (uploadData) => {
    const {media, userId} = uploadData;
    return new Promise(async(resolve, reject) => {
        const data = {
            media,
            userId
        }
        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+"twitter/upload-media",data );
        resolve(result.data);
    })
}

const imageUrlIntoBase64 = (imageUrl) => {
    return new Promise (async (resolve, reject) => {
        const data ={
            url:imageUrl
        }
        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+"twitter/url-image-to-base64", data);
        resolve(result.data);
    })
    

}

const postToTwitter = (data) => {
    return new Promise (async(resolve, reject) => {
        const {text, mediaId,token,userId} = data;
        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+"twitter/post-tweet", data);
        resolve(result.data);
    })
}

const getTwitterUserInfo = (token) => {
    return new Promise(async(resolve, reject)=>{
        const data = {
            token
        }
        const result = await axios.post(process.env.REACT_APP_TWITTER_API_URL+"twitter/get-user", data);
        resolve(result.data);
    })
}
export {getTwitterLongLiveToken, getTwitterNewRefreshToken, twitterUploadMedia, imageUrlIntoBase64,postToTwitter, getTwitterUserInfo}