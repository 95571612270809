import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { createUserWithEmailAndPassword } from "firebase/auth";

import LogoIcon from "../../assets/images/logo-icon.png";
import Button from "../../shared_component/Button/Button";
import BottomTermPrivacy from "../../component/BottomTermPrivacy/BottomTermPrivacy";
import "./SignUp.css";
import Input from "../../shared_component/Input/Input";
import { auth } from "../../firebase/firebase";
import { addingUser } from "../../firebase/database/user";
import { setUserIDAnalytics, signUpEvent } from "../../firebase/analytics/analytics";
import SelectDropDown from "../../shared_component/SelectDropDown/SelectDropDown";
import ReactFlagsSelect from "react-flags-select";
import { Constant } from "../../Constant";

function SignUp() {
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [newsLetter, setNewsLetter] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");
  const [loadingText, setLoadingText] = useState({ show: false, text: "",danger:false });
  const [signUpState, setSignUpState] = useState(0);
  const options = [{
    text:'Male',
  },{
    text:'Female'
  },{
    text:'Other'
  }
]

  const navigate = useNavigate();
  const goto = (url) => {
    navigate(url);
  };

  useEffect(() => {
    document.title = "Sign Up | Berlayar AI";
  },[])

  const handleOnChange = (e) => {
    const form = e.target;
    if (form.name === "name") {
      setName(form.value);
    }
    if (form.name === "email") {
      setEmail(form.value);
    }
    if (form.name === "password") {
      setPassword(form.value);
    }
    if (form.name === "re-password") {
      setRepassword(form.value);
    }
  };

  const signUpUsingEmail = (data) => {
    const { email, password, name } = data;
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        const user = userCredentials.user;
        const userInfo = {
          name: name,
          uid: user.uid,
          email: user.email,
          photoURL: null,
          country:data.country,
          gender:data.gender,
          facebookUID:null,
          longLiveToken:null

        };
        addingUser(userInfo);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        const analyticsData = {
          uid:userInfo.uid,
          email:userInfo.email,
          method:'email'
        }
        setUserIDAnalytics(userInfo.uid);
        signUpEvent(analyticsData);
        setLoadingText({ status: false, text: "" });
        goto("/get-started");
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setLoadingText({
            show: true,
            danger:true,
            text: "Email already exist, please login",
          });
        }
      });
  };

  const handleOnClickDot = (index) => {
    if(index === 0){
      setSignUpState(index);
    }
    if(index === 1){
      const fullname = name;
      const currentCountry = country;
      if(fullname == "" ){
        setLoadingText({
          show: true,
          text: "Please enter your full name",
          danger:true
        });
      }else{
        setLoadingText({
          show: false,
          text: "Please enter your full name",
          danger:true
        });
        if(currentCountry == ""){
          setLoadingText({
            show: true,
            text: "Please choose your country",
            danger:true
          });
        }else{
          setLoadingText({
            show: false,
            text: "Please enter your full name",
            danger:true
          });
          setSignUpState(index);
        }
        
      }
    }

    if(index === 2 ){
      const fullname = name;
      const currentCountry = country;
      if(fullname == "" ){
        setLoadingText({
          show: true,
          text: "Please enter your full name",
          danger:true
        });
      }else{
        setLoadingText({
          show: false,
          text: "Please enter your full name",
          danger:true
        });
        if(currentCountry == ""){
          setLoadingText({
            show: true,
            text: "Please choose your country",
            danger:true
          });
        }else{

      const currentEmail = email;
      const currentPassword = password;
      const currentRePassword = rePassword;
      if(currentEmail == ""){
        setLoadingText({
          show: true,
          text: "Please enter your email address",
          danger:true
        });
      }else{
        if(currentPassword == ""){
          setLoadingText({
            show: true,
            text: "Please input your password",
            danger:true
          });
        }else{
          if(currentPassword.length < 8){
            setLoadingText({
              show: true,
              text: "Your password should be at least 8 characters long.",
              danger:true
            });
          }else{
            if(currentRePassword == ""){
              setLoadingText({
                show: true,
                text: "Please retype your password",
                danger:true
              });
            }else{
              if(currentPassword !== currentRePassword){
                setLoadingText({
                  show: true,
                  text: "Please input the same password",
                  danger:true
                });
              }else{
                setLoadingText({
                  show: false,
                  text: "Please enter your email address",
                  danger:true
                });
                setSignUpState(index);
              }
            }
          }
          
        }
        
      }

        }
        
      }
    }
    
  }

  const handleOnClickNextButton = () => {
    const currentSignUpState = signUpState;
    if(currentSignUpState === 0){
      const fullname = name;
      const currentCountry = country;
      if(fullname == "" ){
        setLoadingText({
          show: true,
          text: "Please enter your full name",
          danger:true
        });
      }else{
        setLoadingText({
          show: false,
          text: "Please enter your full name",
          danger:true
        });
        if(currentCountry == ""){
          setLoadingText({
            show: true,
            text: "Please choose your country",
            danger:true
          });
        }else{
          setLoadingText({
            show: false,
            text: "Please enter your full name",
            danger:true
          });
          setSignUpState(currentSignUpState+1);
        }
        
      }
    }

    if(currentSignUpState === 1){
      const currentEmail = email;
      const currentPassword = password;
      const currentRePassword = rePassword;
      if(currentEmail == ""){
        setLoadingText({
          show: true,
          text: "Please enter your email address",
          danger:true
        });
      }else{
        if(currentPassword == ""){
          setLoadingText({
            show: true,
            text: "Please input your password",
            danger:true
          });
        }else{
          if(currentPassword.length < 8){
            setLoadingText({
              show: true,
              text: "Your password should be at least 8 characters long.",
              danger:true
            });
          }else{
            if(currentRePassword == ""){
              setLoadingText({
                show: true,
                text: "Please retype your password",
                danger:true
              });
            }else{
              if(currentPassword !== currentRePassword){
                setLoadingText({
                  show: true,
                  text: "Please input the same password",
                  danger:true
                });
              }else{
                setLoadingText({
                  show: false,
                  text: "Please enter your email address",
                  danger:true
                });
                setSignUpState(currentSignUpState+1);
              }
            }
          }
          
        }
        
      }
    }
  }

  const handleOnGenderChange = (e) => {
    setGender(e.target.value);
  }

  const submit = () => {
    if(!privacyPolicy){
      setLoadingText({
        show: true,
        text: "Please accept our term and conditions ",
        danger:true
      });
    }else{
      setLoadingText({
        show: true,
        text: "Loading... ",
        danger:false
      });
      const data = {
        name,
        email,
        password,
        rePassword,
        country,
        gender:gender?.text?gender.text:gender,
        privacyPolicy,
        newsLetter
      }
      signUpUsingEmail(data);
    }
  };

  return (
    <div className="signup-container">
      <div className="center-container">
        <div className="item-container">
          <img src={LogoIcon} alt="logo" />
          <p className="signup-subtitle">Sign up for a new account</p>
          <div className="signup-form-container">
          <p style={{ 
              display: loadingText.show ? "block" : "none", 
              color:loadingText.danger?"#F4444C":"#8D8D8D", 
              textAlign:'center',
              width:'100%',
              fontFamily:'Outfit',
              marginBottom:'10px'
              }}>
              {loadingText.text}
            </p>
            {signUpState === 0 && (
              <div className="sign-up-first-section">
              <Input
                type={"text"}
                placeholder="Enter Your Name"
                name="name"
                value={name}
                onChange={handleOnChange}
              /><br />
              <br />
              <ReactFlagsSelect 
                className="flags"
                selected={country}
                onSelect={code=>setCountry(code)}
                />
              <br/>
              <SelectDropDown options={options} className={"select-sign-up"} placeholder={'Gender (Optional)'} onChange={handleOnGenderChange}/>
              <br/>
              <br/>
              <Button text={"Next"} primary={true} onClick={handleOnClickNextButton}/>
              <br/>
              <div className="sign-up-three-dots">
                <div className="sign-up-dots active" onClick={()=>{handleOnClickDot(0)}}></div>
                <div className="sign-up-dots" onClick={()=>{handleOnClickDot(1)}}></div>
                <div className="sign-up-dots" onClick={()=>{handleOnClickDot(2)}}></div>
              </div>
              </div>
            )}
            {signUpState === 1 && (
              <div className="sign-up-second-section">
              <Input
                type="email"
                placeholder="Enter Email Address"
                name={"email"}
                value={email}
                onChange={handleOnChange}
              />
              <br />
              <br />
              <Input
                type="password"
                placeholder="Enter password"
                name={"password"}
                value={password}
                onChange={handleOnChange}
              />
              <br />
              <br />
              <Input
                type="password"
                placeholder="Re-enter password"
                name="re-password"
                value={rePassword}
                onChange={handleOnChange}
              />
              <br />
              <br />
              <Button
                text="Next"
                primary={true}
                padding={"10px 20px"}
                width={"550px"}
                onClick={handleOnClickNextButton}
              />
              <br/><br/>
               <div className="sign-up-three-dots">
                <div className="sign-up-dots"  onClick={()=>{handleOnClickDot(0)}}></div>
                <div className="sign-up-dots active" onClick={()=>{handleOnClickDot(1)}}></div>
                <div className="sign-up-dots" onClick={()=>{handleOnClickDot(2)}}></div>
              </div>
              </div>
            )}

            {signUpState === 2 && (
              <div className="sign-up-third-section">
              <div className="sign-up-privacy">
                <p className="sign-up-privacy-text">
                {Constant.PRIVACY_POLICY}

                </p>
              </div>
              <br />
              <br />
              <div class="form-check checklist-sign-up">
                  
                  <label class="form-check-label text" for="flexCheckDefault">
                  Accept our Terms and Conditions
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={privacyPolicy}
                    id="flexCheckDefault"
                    onChange={()=>{setPrivacyPolicy(!privacyPolicy);}}
                  />
                </div>
              <br />
              <br />
              <div class="form-check checklist-sign-up">
                 
                  <label class="form-check-label text" for="flexCheckDefault">
                  Sign up for our newsletter
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={newsLetter}
                    id="flexCheckDefault"
                    onChange={()=>{setNewsLetter(!newsLetter);}}
                  />
                </div>
                <br/><br/>
              <Button
                text="Sign Up"
                primary={true}
                padding={"10px 20px"}
                width={"550px"}
                onClick={submit}
              />
              <br/><br/>
               <div className="sign-up-three-dots">
                <div className="sign-up-dots" onClick={()=>{handleOnClickDot(0)}}></div>
                <div className="sign-up-dots" onClick={()=>{handleOnClickDot(1)}}></div>
                <div className="sign-up-dots active" onClick={()=>{handleOnClickDot(2)}}></div>
              </div>
              </div>
            )}

            
          </div>
         
        </div>
      </div>
      <div className="sign-up-bottom-page">
        <div></div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center', width:300}}>
        <p className="sign-up">
            Already have an account ?{" "}
            <span
              onClick={() => {
                goto("/login");
              }}
            >
              Login
            </span>
          </p>
        </div>
      
          <BottomTermPrivacy />
      </div>
      
    </div>
  );
}

export default SignUp;
