import React, { useEffect, useState } from "react";

import "./GenerateUpload.css";
import { FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Button from "../../../shared_component/Button/Button";
import BerlayarModal from "../../../shared_component/Modal/Modal";
import SelectDropDown from "../../../shared_component/SelectDropDown/SelectDropDown";
import { getUserInfoFromLocalStorage } from "../../../utils/localStorage";
import {
  getFacebookPageList,
  uploadToFacebook,
} from "../../../services/facebookAPI/facebookApi";
import ModalFacebook from "../ModalFacebook/ModalFacebook";
import {
  donwloadContentInZIP,
  downloadCaption,
  downloadImage,
} from "../../../services/Donwload/DownloadContent";
import ModalTwitter from "./ModalTwitter/ModalTwitter";
import { getTwitterNewRefreshToken, getTwitterUserInfo, imageUrlIntoBase64, postToTwitter, twitterUploadMedia } from "../../../services/TwitterAPI/TwitterAPI";
import { updateUser } from "../../../firebase/database/user";

function GenerateUpload({ result, show }) {
  const [scheduleState, setScheduleState] = useState(false);
  const [date, setdate] = useState(null);
  const [time, setTime] = useState(null);
  const today = new Date().toISOString().split("T")[0];
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30);
  const maxDate = futureDate.toISOString().split("T")[0];
  const [showModalFacebook, setShowModalFacebook] = useState(false);
  const [showModalTwitter, setShowModalTwitter] = useState(false);
  const [selectedFacebookPage, setSelectedFacebookPage] = useState(null);
  const [alert, setAlert] = useState("");
  const [uploading, setUploading] = useState(false);
  const [selectedTwitter, setSelectedTwitter] = useState(false);

  const toggleShowModalFacebook = () => {
    const currentModalFacebook = showModalFacebook;
    setShowModalFacebook(!currentModalFacebook);
  };

  const toggleShowModalTwitter = () => {
    const currentShowModalTwitter = showModalTwitter;
    setShowModalTwitter(!currentShowModalTwitter)
  }

  const onTwitterClick = () => {
    const userInfo = getUserInfoFromLocalStorage();
    const currentSelectedTwitter = selectedTwitter;
    if(currentSelectedTwitter){
      setSelectedTwitter(false);
    }else{
      if(userInfo.twitter_access_token !== undefined){
        setSelectedTwitter(true);
      }else{
        toggleShowModalTwitter();
      }
    }
   
  }

  const onFacebookClick = () => {
    if (selectedFacebookPage === null) {
      toggleShowModalFacebook();
    } else {
      setSelectedFacebookPage(null);
    }
  };

  const toggleScheduleState = () => {
    const currentScheduleState = scheduleState;
    setScheduleState(!currentScheduleState);
  };

  const chooseFacebookPage = (facebookPage) => {
    setSelectedFacebookPage(facebookPage);
  };

  const handleOnChangeForm = (e) => {
    const formName = e.target.name;
    if (formName === "date") {
      const choosenDate = e.target.value;
      setdate(choosenDate);
    }

    if (formName === "time") {
      const choosenTime = e.target.value;
      setTime(choosenTime);
    }
  };

  const handleDownload = async() => {
    imageUrlIntoBase64(result.imageUrl).then((resultBase64) => {
      if(resultBase64.success){
        const base64 = resultBase64.data;
        donwloadContentInZIP(base64,result.description);
      }
    })
  };

  const checkIfTheTimeIsCorrect = (selectedTime) => {
    const now = Math.floor(Date.now() / 1000);
    const fifteenMinutesInSeconds = 15 * 60;
    const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
    const fifteenMinutesFromNow = now + fifteenMinutesInSeconds;
    const thirtyDaysFromNow = now + thirtyDaysInSeconds;

    if (
      selectedTime >= fifteenMinutesFromNow &&
      selectedTime <= thirtyDaysFromNow
    ) {
      return true;
    } else {
      return false;
    }
  };

  const publishToTwitter = async() => {
    setAlert("Communicate to Twitter(X) ...");
    setUploading(true);
    const userInfo = getUserInfoFromLocalStorage();
    const refreshToken = userInfo.twitter_refresh_token;
    const content = result;
    getTwitterNewRefreshToken(refreshToken).then(async(result) => {
      if(result.success){
        const data = result.data;
        userInfo.twitter_access_token = data.access_token;
        userInfo.twitter_refresh_token = data.refresh_token;
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        await updateUser(userInfo);
        imageUrlIntoBase64(content.imageUrl).then((resultBase64) => {
          if(resultBase64.success){
            const base64 = resultBase64.data;
            getTwitterUserInfo(userInfo.twitter_access_token).then((user) => {
              const uploadData = {
                media:base64,
                userId:user.data.id
              }
              twitterUploadMedia(uploadData).then((result) => {
                const uploadedImage = result.data;
                const postData = {
                  text:generateRandomString(50),
                  mediaId:uploadedImage.media_id_string,
                  token:userInfo.twitter_access_token,
                }
                postToTwitter(postData).then((postResult) => {
                  setUploading(false);
                  setAlert("Successfully Uploaded to Twitter(X)")
                })
              })
            })
            
          }
        })
       
      }else{

      }
      
    }).catch((error) => {console.log(error)})
  }

  const  generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  }

  const publishToFacebook = async() => {
    setAlert("Communicate to Facebook ...");
    setUploading(true);
    if (selectedFacebookPage !== null) {
      if (scheduleState) {
        setAlert("Try to create schedule post into Facebook ...");
        if (date !== null && time !== null) {
          const dateTimeString = `${date}T${time}:00`;
          const dateTime = new Date(dateTimeString);
          const unixDate = Math.floor(dateTime.getTime() / 1000);
          const dateRange = checkIfTheTimeIsCorrect(unixDate);
          if (dateRange) {
            const access_token = selectedFacebookPage.access_token;
            const message = result.description;
            const url =
              result.imageUrl;
            const pageId = selectedFacebookPage.id;
            const published = !scheduleState;
            const scheduled_publish_time = unixDate;
            const data = {
              access_token,
              message,
              url,
              pageId,
              published,
              scheduled_publish_time,
            };

            const results = await uploadToFacebook(data);
            if (results) {
              setAlert(
                "The content successfully schedule to post on facebook at " +
                  date +
                  "|" +
                  time
              );
              setUploading(false);
            } else {
              setAlert("Something went wrong, please try again later");
              setUploading(false);
            }
          } else {
            setAlert(
              "The schedule date must be between 15 minutes and 30 days from now"
            );
            setUploading(false);
          }
        } else {
          setAlert("Please choose date and time to post the content ");
          setUploading(false);
        }
      } else {
        const access_token = selectedFacebookPage.access_token;
        const message = result.description;
        const url =
          result.imageUrl;
        const pageId = selectedFacebookPage.id;
        const published = !scheduleState;
        const data = {
          access_token,
          message,
          url,
          pageId,
          published,
        };
        const results = await uploadToFacebook(data);
        if (results) {
          setUploading(false);
          setAlert(
            "The content has been uploaded to your facebook page " +
              selectedFacebookPage.name
          );
        } else {
        }
      }
    } else {
      setAlert("Please select Facebook Page ");
      setUploading(false);
    }
  }

  const handlePublish = async () => {
    setAlert("");
    if(selectedTwitter){
      if(!scheduleState){
        publishToTwitter();
      }else{
        setAlert("Schedule post for Twitter(X) is currently not available, Please disable schedule post, to publish this content to Twitter(X)");
      }

    }
    
    
  };

  return (
    <div style={{ display: show ? "flex" : "none", flexDirection: "column" }}>
      <BerlayarModal
        show={showModalFacebook}
        toggle={toggleShowModalFacebook}
        content={
          <ModalFacebook
            chooseFacebookPage={chooseFacebookPage}
            toggle={toggleShowModalFacebook}
          />
        }
      ></BerlayarModal>
       <BerlayarModal
        show={showModalTwitter}
        toggle={toggleShowModalTwitter}
        content={<ModalTwitter setSelectedTwitter={setSelectedTwitter} toggle={toggleShowModalTwitter}/>}
      />
      <p className="title-text">Upload</p>
      <div className="edit-main-container">
        <img src={result.imageUrl} alt="selected" className="edit-main-image" />
        <textarea
          className="edit-main-caption"
          value={result.description}
        ></textarea>
      </div>
      <div className="generate-upload-schedule-post">
        <FormGroup switch>
          <Input
            type="switch"
            role="switch"
            value={scheduleState}
            onChange={toggleScheduleState}
          />
          <Label check>Schedule Post</Label>
        </FormGroup>
      </div>
      {scheduleState && (
        <div className="generate-upload-form-modal-date-and-time-container">
          <div>
            <FormGroup>
              <Label for="exampleDate">Date</Label>

              <Input
                id="exampleDate"
                name="date"
                placeholder="date placeholder"
                type="date"
                value={date}
                min={today}
                max={maxDate}
                onChange={(e) => handleOnChangeForm(e)}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <Label for="exampleTime">Time</Label>
              <Input
                id="exampleTime"
                name="time"
                placeholder="time placeholder"
                type="time"
                value={time}
                onChange={(e) => handleOnChangeForm(e)}
              />
            </FormGroup>
          </div>
        </div>
      )}
      <div>
        <p className="generate-upload-text">Upload To:</p>
      </div>
      <div className="generate-upload-social-media-container">
        <FontAwesomeIcon
          icon={faSquareFacebook}
          className={
            selectedFacebookPage !== null
              ? "generate-upload-social-media-icon active"
              : "generate-upload-social-media-icon"
          }
          onClick={()=>{onFacebookClick()}}
        />
        <FontAwesomeIcon
          icon={faSquareInstagram}
          className={"generate-upload-social-media-icon"}
        />
        <FontAwesomeIcon
          icon={faSquareXTwitter}
          className={selectedTwitter?"generate-upload-social-media-icon active":"generate-upload-social-media-icon"}
          onClick={()=> {onTwitterClick()}}
        />
      </div>
      <div>
        {selectedFacebookPage !== null ? (
          <>
            <div className="facebook-page-card"></div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="generate-upload-button-container">
        {uploading ? (
          <Button
            text={scheduleState ? "Scheduling..." : "Publishing..."}
            primary={false}
            width={150}
          />
        ) : (
          <Button
            text={scheduleState ? "Schedule" : "Publish"}
            primary={true}
            width={150}
            onClick={()=>{handlePublish()}}
          />
        )}
        <Button
          text={"Download"}
          primary={false}
          width={150}
          onClick={handleDownload}
        />
      </div>
      <div>
        <p>{alert}</p>
      </div>
    </div>
  );
}

export default GenerateUpload;
