import React, { useState } from "react";

import "./GenerateEditPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../shared_component/Button/Button";
import { Constant } from "../../../Constant";
import { donwloadContentInZIP, downloadCaption } from "../../../services/Donwload/DownloadContent";
import { generateContent } from "../../../services/generateContentApi";
import SailingAnimation from '../../../assets/icon/sailing.gif';
import { imageUrlIntoBase64 } from "../../../services/TwitterAPI/TwitterAPI";

function GenerateEditPage({ result, uploadClick, show, resultList, setResult, setResultList }) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedCaption, setSelectedCaption] = useState(0);
  const [listCaption, setListCaption] = useState([result.description]);
  const [regenerateCaption, setRegenerateCaption] = useState(false);
  const [regenerateImage, setRegenerateImage] = useState(false);
  const [choosenContent, setChoosenContent] = useState(result);
  const [mainCaption, setMainCaption] = useState(choosenContent.description);
  const [listRegenerate, setListRegenerate] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingCaption, setLoadingCaption] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnSaveChange = () => {
    setResult(choosenContent);
    const listOfResult = resultList;
    const index = listOfResult.findIndex(item=> item.id === choosenContent.id);
    if(index !== -1){
      listOfResult[index] = choosenContent;
      setResultList(listOfResult);
    }

  }

  const handleOnImageClick = (index) => {
    const arrayRegenerate = [...listRegenerate];
    setSelectedImage(index);
    onChoosenImageChange(arrayRegenerate[index].imageUrl);
  };

  const handleOnCaptionClick = (index) => {
    const arrayRegenerate = [...listRegenerate];
    setSelectedCaption(index);
    onChoosenCaptionChange(arrayRegenerate[index].description);
    setMainCaption(listCaption[index]);
  };

  const onChoosenImageChange = (imageUrl) => {
    setChoosenContent((prevContent) => ({
      ...prevContent,
      imageUrl:imageUrl  
    }));
  }

  const onChoosenCaptionChange = (caption) => {
    setChoosenContent((prevContent) => ({
      ...prevContent,
      description:caption
    }));
  }

  const handleOnRegenerateCaptionClick = async() => {
    const currentListRegenerate = [...listRegenerate];
    setLoading(true)
    setLoadingCaption(true);
    if(currentListRegenerate.length === 0 ){
      
      const resultArray = [];
      resultArray.push(result);
        for (let i = 0; i < 4;i++){
          const generatedContent = await generateContent(
            result.title
          );
          if (generatedContent !== null) {
            const description = generatedContent.article.choices[0].text;
            const imageUrl = generatedContent.image_url.data[0].url;
            const imagePrompt = generatedContent.image_url.data[0].revised_prompt;
            const title = result.title;
            const newResult = {
              id:i,
              description,
              imageUrl,
              imagePrompt,
              title
              
            };
            resultArray.push(newResult)
          }
        }
        setListRegenerate(resultArray);
        setLoading(false);
        setLoadingCaption(false);
        setRegenerateCaption(true);
    }else{
      setRegenerateCaption(true);
      setLoading(false);
      setLoadingCaption(false);
    }
    
  };

  const handleRegenerateImageClick = async() => {
    const currentListRegenerate = [...listRegenerate];
    setLoading(true);
    setLoadingImage(true);
    if(currentListRegenerate.length === 0 ){
      
      const resultArray = [];
        resultArray.push(result);
        for (let i = 0; i < 4;i++){
          const generatedContent = await generateContent(
            result.title
          );
          if (generatedContent !== null) {
            const description = generatedContent.article.choices[0].text;
            const imageUrl = generatedContent.image_url.data[0].url;
            const imagePrompt = generatedContent.image_url.data[0].revised_prompt;
            const title = result.title;
            const newResult = {
              id:i,
              description,
              imageUrl,
              imagePrompt,
              title
              
            };
            resultArray.push(newResult)
          }
        }
        setListRegenerate(resultArray);
        setLoading(false);
        setLoadingImage(false);
        setRegenerateImage(true);
    }else{
      setRegenerateImage(true);
      setLoading(false);
      setLoadingImage(false);
    }
  };

  const handleDownload = async() => {
    imageUrlIntoBase64(result.imageUrl).then((resultBase64) => {
      if(resultBase64.success){
        const base64 = resultBase64.data;
        donwloadContentInZIP(base64,result.description);
      }
    })
  };
  return (
    <div style={{ display: show ? "flex" : "none", flexDirection: "column" }}>
      <p className="title-text">
        <b>Edit</b>
      </p>
      <div className="edit-main-container">
        <img src={choosenContent.imageUrl} alt="selected" className="edit-main-image" />
        <textarea
          className="edit-main-caption"
          value={choosenContent.description}
          onChange={(e) => {
            onChoosenCaptionChange(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="edit-regenerate-image-button-container">
        <Button
          text={"Regenerate Image"}
          primary={loading||loadingImage|| regenerateImage ? false : true}
          width={250}
          onClick={loading||loadingImage|| regenerateImage ? () => {} : ()=>{handleRegenerateImageClick()}}
        />
      </div>
      {loadingImage && (
         <div className="loading-container">
            <img src={SailingAnimation} alt="loading-animation" className="loading-animation"/>
            <p className="loading-text">Loading ...</p>
         </div>
      )}
      {regenerateImage && (
        <div className="edit-regenerate-image-container">
          {listRegenerate.map((regenerate, index) => {
            return(
              <div
                className={
                  selectedImage === index
                    ? "image-overlay active"
                    : "image-overlay"
                }
                onClick={() => handleOnImageClick(index)}
              >
                <img
                  src={regenerate.imageUrl}
                  alt="regenerate"
                  className="edit-regenerate-image-result"
                />
                <div className="edit-icon-container">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="edit-icon"
                    style={{ color: "#FFF", fontSize: 32 }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}

      <div className="edit-regenerate-captions-button-container">
        <Button
          text={"Regenerate Captions"}
          primary={loading|| loadingCaption|| regenerateCaption ? false : true}
          width={250}
          onClick={
            loading || loadingCaption|| regenerateCaption ? () => {} : () =>{handleOnRegenerateCaptionClick()}
          }
        />
      </div>
      {loadingCaption && (
         <div className="loading-container">
            <img src={SailingAnimation} alt="loading-animation" className="loading-animation"/>
            <p className="loading-text">Loading ...</p>
         </div>
      )}
      {regenerateCaption && (
        <div className="edit-regenerate-text-container">
          {listRegenerate.map((regenerate, index) => {
            return(
              <div
                className="edit-regenerate-item"
                onClick={() => {
                  handleOnCaptionClick(index);
                }}
              >
                <p className="edite-regenaret-title">
                  <b>{index === 0 ? "Original Caption" : "Option " + index}</b>
                </p>
                <div className="edit-regenerate-text-row">
                  <p className="edit-regenerate-text-caption">{regenerate.description}</p>
                  {selectedCaption === index ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ fontSize: 24, color: "#F4444C" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{ fontSize: 24, color: "#8d8d8d" }}
                    />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}

      <div className="edit-regenerate-button-container">
        
        <Button text={"Save Change"} primary={true} width={200} onClick={()=>{handleOnSaveChange()}}/>
        <Button
          text={"Upload"}
          
          onClick={uploadClick}
          width={150}
        />
        <Button text={"Download"} width={150} onClick={handleDownload} />
      </div>
    </div>
  );
}

export default GenerateEditPage;
