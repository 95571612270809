import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";

import "./GetStarted.css";
import LogoWhite from "../../assets/images/logo-white.png";
import Button from "../../shared_component/Button/Button";
import BottomTermPrivacy from "../../component/BottomTermPrivacy/BottomTermPrivacy";
import {
  auth,
  faceboookProvider,
  googleProvider,
  twitterProvider,
} from "../../firebase/firebase";
import { addingUser, getUserInfo } from "../../firebase/database/user";
import {
  loginEvent,
  setUserIDAnalytics,
  signUpEvent,
} from "../../firebase/analytics/analytics";
import { getLongLiveToken } from "../../services/facebookAPI/facebookApi";
import ModalUserPreference from "./ModalUserPreference";

function GetStarted() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const questionList = [
    {
      category: "interest",
      question: "What would you like to do ?",
      options: [
        "Refactor codebase",
        "Write test",
        "Chat with Knowledgebase",
        "Generate Code",
        "Generate Social Media Content",
      ],
    },
    {
      category: "content-type",
      question: "What type of content are you most interested in?",
      options: [
        "News",
        "Technology",
        "Lifestyle",
        "Health",
        "Education",
        "Entertainment",
        "Other",
      ],
    },
    {
      category: "frequently-post",
      question: "How frequently would you prefer to see new post ?",
      options: [
        "Daily",
        "2-3 times a week",
        "Weekly",
        "Bi-weekly",
        "Monthly",
        "Other",
      ],
    },
    {
      category: "tone",
      question: "What tone would you prefer for the content?",
      options: [
        "Formal",
        "Casual",
        "Professional",
        "Humorous",
        "Educational",
        "Other",
      ],
    },
    {
      category: "interest",
      question: "Let's Your Journey Begin",
      options: ["Go To Dashboard"],
    },
  ];

  useEffect(() => {
    checkUserInfo();
    document.title = "Get Started | Berlayar AI";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    const show = showModal;
    setShowModal(!show);
  };

  const checkUserInfo = () => {
    const userInfos = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfos != null) {
      toggleModal();
    }
  };

  const storeUserInfoToLocalStorage = (userInfo) => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  };

  const signUpWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    provider.addScope("email");
    provider.addScope("pages_show_list");
    provider.addScope("instagram_basic");
    provider.addScope("instagram_content_publish");
    provider.addScope("pages_read_engagement");
    provider.addScope("pages_read_user_content");
    provider.addScope("pages_manage_posts");
    provider.addScope("pages_manage_engagement");
    provider.setCustomParameters({
      display: "popup",
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credentials = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credentials.accessToken;
        const longLiveToken = await getLongLiveToken(accessToken);
        const facebookUID = result.user.providerData[0].uid;
        const userInfo = {
          name: result.user.displayName,
          uid: result.user.uid,
          email: result.user.email,
          photoURL: result.user.photoURL,
          longLiveToken,
          facebookUID,
        };
        const newUserInfo = await getUserInfo(userInfo.uid);
        if (newUserInfo === null) {
          addingUser(userInfo);
          storeUserInfoToLocalStorage(userInfo);
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "facebook",
          };
          setUserIDAnalytics(userInfo.uid);
          signUpEvent(analyticsData);
          checkUserInfo();
        } else {
          storeUserInfoToLocalStorage(userInfo);
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "facebook",
          };
          setUserIDAnalytics(userInfo.uid);
          loginEvent(analyticsData);
          goto("/generate");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signUpWithTwitter = () => {
    signInWithPopup(auth, twitterProvider)
      .then(async (result) => {
        const userInfo = {
          name: result.user.displayName,
          uid: result.user.uid,
          email: result.user.email,
          photoURL: result.user.photoURL,
          country:null,
          gender:null,
          facebookUID:null,
          longLiveToken:null
        };
        const newUserInfo = await getUserInfo(userInfo.uid);
        if (newUserInfo === null) {
          addingUser(userInfo);
          storeUserInfoToLocalStorage(userInfo);
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "twitter",
          };
          setUserIDAnalytics(userInfo.uid);
          signUpEvent(analyticsData);
          checkUserInfo();
        } else {
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "twitter",
          };
          setUserIDAnalytics(userInfo.uid);
          loginEvent(analyticsData);
          storeUserInfoToLocalStorage(userInfo);
          goto("/generate");
        }
      })
      .catch((error) => console.error("Ada masalah", error));
  };

  const signUpwithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const userInfo = {
          name: result.user.displayName,
          uid: result.user.uid,
          email: result.user.email,
          photoURL: result.user.photoURL,
          country:null,
          gender:null,
          facebookUID:null,
          longLiveToken:null
        };
        const newUserInfo = await getUserInfo(userInfo.uid);
        if (newUserInfo === null) {
          addingUser(userInfo);
          storeUserInfoToLocalStorage(userInfo);
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "google",
          };
          setUserIDAnalytics(userInfo.uid);
          signUpEvent(analyticsData);
          checkUserInfo();
        } else {
          const analyticsData = {
            uid: userInfo.uid,
            email: userInfo.email,
            method: "google",
          };
          const user = await getUserInfo(userInfo.uid);
          const currentUserInfo = {
            name: user.name,
            uid: user.uid,
            email: user.email,
            photoURL: user.photoURL,
            country:user.country,
            gender:user.gender,
            facebookUID:user.facebookUID,
            longLiveToken:user.longLiveToken
          }
          setUserIDAnalytics(userInfo.uid);
          loginEvent(analyticsData);
          storeUserInfoToLocalStorage(currentUserInfo);
          goto("/generate");
        }
      })
      .catch((error) => console.log(error));
  };

  const goto = (url) => {
    navigate(url);
  };

  return (
    <div className="get-started-container">
      <div className="modal-container">
        <ModalUserPreference
          userPreferenceQuestion={questionList}
          show={showModal}
        />
      </div>
      <div className="center-container">
        <div className="item-container">
          <img src={LogoWhite} alt="logo" />
          <p className="get-started-subtitle">Get Started</p>
          <div className="get-started-button-container">
            <Button
              text="Login"
              primary={true}
              padding={"10px 20px"}
              width={"120px"}
              onClick={() => {
                goto("/login");
              }}
            />
            <br />
            <Button
              text="Sign Up"
              primary={true}
              padding={"10px 20px"}
              width={"120px"}
              onClick={() => {
                goto("/sign-up");
              }}
            />
          </div>
        </div>
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <div className="get-started-divider">
            <div className="get-started-divider-line"></div>
            <p className="get-started-divider-text">OR</p>
            <div className="get-started-divider-line"></div>
          </div>
          <div className="social-media-login-container">
            <div
              className="social-media-login-button"
              onClick={() => {
                signUpwithGoogle();
              }}
            >
              <FontAwesomeIcon
                icon={faGoogle}
                className="social-media-login-button-icon"
              />
              <p className="social-media-login-button-text">
                Login with Google
              </p>
            </div>
          </div>
          <div className="social-media-login-container">
            <div
              className="social-media-login-button"
              onClick={() => {
                signUpWithFacebook();
              }}
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="social-media-login-button-icon"
              />
              <p className="social-media-login-button-text">
                Login with Facebook
              </p>
            </div>
          </div>
          <div className="social-media-login-container">
            <div
              className="social-media-login-button"
              onClick={() => {
                signUpWithTwitter();
              }}
            >
              <FontAwesomeIcon
                icon={faTwitter}
                className="social-media-login-button-icon"
              />
              <p className="social-media-login-button-text">
                Login with Twitter
              </p>
            </div>
          </div>
        </div>
      </div>
      <BottomTermPrivacy />
    </div>
  );
}

export default GetStarted;
