import React from 'react'

import './SideBar.css'
import LogoBlack from '../../../assets/images/logo-black.png'
import Button from '../../../shared_component/Button/Button'
import IconDot from '../../../assets/icon/icon-dot.png'
import PlaceHolder from '../../../assets/images/img_placeholder.png'
import { getUserInfoFromLocalStorage } from '../../../utils/localStorage'
import { buttonClickEvent } from '../../../firebase/analytics/analytics'

function Sidebar(props) {

    const addToAnalytics = () => {
      const userInfo = getUserInfoFromLocalStorage();
      if(userInfo){
        const dataAnalitycs ={
          buttonName:"new_chat",
          uid:userInfo.uid,
          email:userInfo.email
        }
        buttonClickEvent(dataAnalitycs)
      }
    }
    const newChat = () => {
      addToAnalytics();
      const currentList = props.chatList;
      const newItem = {
        id:Math.random(),
        title:'New Chat',
        chat:[
          {content:'welcome',
          ai:true}
        ]
      }

      props.addChat([...currentList,newItem])
      props.setSelect(newItem)
    }

  
  return (
    <div className='sidebar'>
      <div className='menu'>
        <img className='menu-logo' src={LogoBlack} alt='logo-black'/>
        <div style={{display:"flex", justifyContent:"center"}}>
        <Button text={"New Chat"} primary={true} width={"80%"} onClick={newChat}/>
        </div>
        <br/>
        {props.chatList.map((chat,index) => {
          return(
           <div className={props.selectedChat.id===chat.id?'menu-item active':'menu-item'} onClick={() => props.setSelect(chat)}>
            <p className='menu-item-text'>{chat.title}</p>
           </div>
         )
        })}
        
      </div>
      <div className='bottom-menu'>
            <div className='account-menu'>
                <img src={PlaceHolder} className='profile-pic' alt='profile-pic'/>
                <p>Jhone Done</p>
                <img src={IconDot} alt='icon-dot'/>
            </div>
      </div>
    </div>
  )
}

export default Sidebar