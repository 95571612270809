import axios from "axios";


const BASE_URL = "https://articles-thy27kloba-uc.a.run.app/generate";
const generateContent = async(prompt) => {

    const body = {
        prompt
    }

    const headers = {
        headers:{
            'Content-Type':'application/json',
        }
    }
        const result = await axios.post(BASE_URL,body,headers)
        const data = result.data;
        if(data){
            return data;
        }else{
            return null;
        }
        
}

export {generateContent}