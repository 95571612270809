import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import Logo from '../../assets/images/logo-berlayar.png'
import ButtonNavBar from './ButtonNavBar'
import "./NavBar.css"

function NavBar(props) {
   
    const[menuOpen, setMenuOpen] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(()=> {
       getUserInfo();
    },[])

    const getUserInfo = () => {
        const localUserInfo = localStorage.getItem('userInfo')
        setUserInfo(localUserInfo)
    }

  return (
    <div >
    <nav className='container-global-navbar'>
        <Link to={'/'}><img src={Logo} alt='Logo'/></Link>
        <div className='menu' onClick={()=>{setMenuOpen(!menuOpen)}}>
            <FontAwesomeIcon icon={faBars} style={{color:'#000', fontSize:24}}/>

        </div>
        <ul className={menuOpen? "open": ""}>
            <li>
            <Link to={'/about'}><ButtonNavBar text="About" button={false}></ButtonNavBar></Link>
            </li>
            <li>
                <Link ><ButtonNavBar text="Services" button={false}></ButtonNavBar></Link>
            </li>
            <li>
                {userInfo === null ? (<>
                    <Link to={'/get-started'}><ButtonNavBar text="Get Started" button={true}></ButtonNavBar></Link>
                </>):(<>
                    <Link to={'/dashboard'}><ButtonNavBar text="Dashboard" button={true}></ButtonNavBar></Link>
                </>)}
               
            </li>
        </ul>
    </nav>
    <div>
        {props.content}
    </div>
    </div>
  )
}

export default NavBar