import { logEvent, setUserId} from "firebase/analytics";
import {analytics} from '../firebase';

export const loginEvent = (data) => {
    logEvent(analytics,"login_event", data);
}

export const signUpEvent = (data) => {
    logEvent(analytics, "signup_event", data);
}

export const setUserIDAnalytics = (uid) => {
    setUserId(analytics,uid);
}

export const buttonClickEvent = (data) => {
    const {buttonName} = data;
    logEvent(analytics, "button_"+buttonName+"_clicked", data);
}

export const pageVisited = (data) => {
    const {pageName} = data;
    logEvent(analytics, "page_"+pageName+"_visited", data);
}