export const Constant = {
    LOREM_IPSUM :`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`,
    PRIVACY_POLICY:`Code for Asia Society Ltd. is committed to protecting and respecting your privacy. 

    A. OVERVIEW  
    
    This policy (together with any other documents referred to in it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.  
    
    You must read the entirety of this Privacy Policy carefully before using this website at https://codefor.asia (our site) (the “Website”). By registering your information to use or access the information provision service by us (the “Service”) through any channel or method, by using or accessing the Website, or by communicating with Code for Asia Society (“Company”, “we”, “us” or “our”) regarding the Service and/or by accepting the Terms and Conditions (“Terms”), you agree and consent to the use, collection, sharing and other processing of your data by Company under this Privacy Policy. If you do not agree to this Privacy Policy, do not use any Service. 
    
    B. COLLECTION OF PERSONAL DATA  
    
    In order to provide a better service to you, we may collect and process the following data about you:
    
    information that you provide by filling in forms on our site https://codefor.asia (our site). This includes information provided at the time of registering to use our site, subscribing to our service, posting material or requesting further services. We may also ask you for information at other times, for example in connection with a promotion or when you report a problem with our site; 
    if you contact us, we may keep a record of that correspondence; 
    we may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them; 
    details of transactions you carry out through our site and of the fulfilment of your orders; 
    details of your visits to our site and the resources that you access. 
    any information recorded from classes/training. 
    We only retain personal data for so long as it is necessary. Data may be archived as long as the purpose for which the data was used still exists. 
    
    C. USES MADE OF THE INFORMATION 
    
    The purposes for which information may be used by us in and outside Singapore include:
    
    ensuring that content from our site is presented in the most effective manner for you and for your computer;
    providing you with alerts, newsletter, education materials or information that you requested or signed up to;
    carrying out our obligations arising from any contracts entered into between you and us;
    allowing you to participate in interactive features of our service, when you choose to do so;
    designing and conducting surveys/questionnaires for client profiling/segmentation, statistical analysis, improving and furthering the provision our products and services;
    complying with laws and regulations applicable to us or any of our affiliates in or outside Singapore;
    legal proceedings, including collecting overdue amounts and seeking professional advices;
    researching, designing and launching services or products including seminars/events/forums;
    For internal business purposes, including research, analysis and testing
    promoting and marketing services and products subject to your exercise of the opt-out right (please see further details in clause 2.2 below); or
    detect, diagnose and fix problems affecting the website ;
    protecting our rights and property and the rights, property and safety of others
    purposes directly related or incidental to the above.
    We may use and disclose automatically collected information (including aggregated information), which is not personal data, for any purpose, except where we are restricted by applicable law. If we combine any automatically collected information with personal data or other information which may be used to identify an individual, the combined information will be treated by us as personal data in accordance with this Privacy Policy.
    
    We intend to use your data in direct marketing and we require your consent (which includes an indication of no objection) for that purpose. In this connection, please note that:
    
    your name, contact details (including address, contact number, email address), products and services information, transaction pattern and behaviour, background and demographic data held by us from time to time may be used by us in direct marketing;
    the following classes of services, products and subjects may be marketed in direct marketing:
    services and products related to our site and/or our affiliates (including marketing affiliates programs we are a part of);
    reward, loyalty or privileges programmes, promotional offers and related services; and
    invitations to events such as seminars/webinars/tele-seminars, conferences, live programs or events.
    We may conduct direct marketing via fax, email, direct mail, telephone and other means of communication or send e-newsletters to you. You may choose not to receive promotional materials, by simply telling us (see below for contact details), and we will cease to do so, without charge.
    
    D. DISCLOSURE OF YOUR INFORMATION 
    
    We will keep the personal data we hold confidential but may provide information to:
    
    personnel, agents, advisers, auditors, contractors, financial institutions, and service providers in connection with our operations or services;
    our overseas offices, affiliates, business partners and counterparts (if any);
    persons under a duty of confidentiality to us;
    persons to whom we are required to make disclosure under applicable laws and regulations in or outside Singapore; or
    actual or proposed transferees or participants of our services in or outside Singapore.
    D.A. SHARING AND CROSS-BORDER TRANSFER OF PERSONAL DATA  
    
    We may hold your data on servers in the Asia-Pacific region and any other territories as we see fit from time to time. We may also transfer your data to our overseas offices or to any people listed at clause 3.1 above, who may be located in or outside of Singapore. However, the level of protection that will be applied to the transferred personal information will be at least comparable to the protection provided under this Privacy Policy and PDPA and its regulations. 
    
    We may also share your personal data with other third parties if you have separately consented to such disclosure. In such case, any information which we share with such third parties shall be used for the purposes described when we obtained your consent. 
    
    We may share your personal data with any other person where such disclosure is required or permitted under applicable law or regulation, and we may be prevented from obtaining your consent for, or informing you of, such disclosure. Any information which we share in this circumstance shall be used for the purposes required or permitted under applicable law or regulation. 
    
    E. COOKIES 
    
    Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.
    
    A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
    
    We use the following cookies:
    
    Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services. 
    Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily. 
    Functionality cookies. These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region). 
    Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose. 
    Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. 
    
    Our website uses Google Analytics, a web traffic analysis service provided by Google Inc. (“Google”). Please refer to http://www.google.com/policies/privacy/partners to find out more about how Google uses data when you use our website and how to control the information sent to Google. 
    You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site. 
    Furthermore, you can prevent Google’s collection and processing of data by using the Google Ads Settings page or downloading and installing their browser plug-in (https://tools.google.com/dlpage/gaoptout). 
    
    F. SECURITY/STORING PERSONAL DATA  
    
    All information you provide to us is stored on our secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. 
    
    All information collected in connection with any Service (including information generated by cookies about your use of the Website) will be stored electronically on the server of our service provider with appropriate safeguards and security measures. However, there is no guarantee that information stored by us or on our behalf may not be accessed, collected, used, disclosed, copied, modified or deleted by unauthorised parties by breach of any technical or operational safeguards in place. We will not be keeping physical files of any personal data. 
    
    The transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk and you agree not to hold us responsible for any breach of security while accessing the internet that is out of our control. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.
    
    You are at all times responsible for your own Internet privacy settings. We are not responsible for the functionality, privacy or security measures of any other organisation or third-party websites, applications or services. 
    
    G. THIRD PARTY WEBSITES 
    
    Our website may contain links to other sites (“Linked Sites“). We are not responsible for the privacy policies or practices of such other Linked Sites. We encourage our users to be aware when leaving our website to read the privacy policies or statements of each Linked Site that collects your personal data. You agree to waive any claim against us with respect to the Linked Sites. 
    
    H. CHANGES/UPDATE/DELETE TO OUR PRIVACY POLICY 
    
    We reserve the right to update, modify, delete or otherwise make changes to this Privacy Policy at any time. Any changes we may make to our Privacy Policy in the future will be posted on this page. Any changes to this Privacy Policy will take effect and be binding on you from the time of posting the changes to the Website. Your continued use of our services constitutes your agreement to this Privacy Policy and any updates. If you do not agree with the changes, please stop participating in any Service. Please ensure that you refer to this Privacy Policy on a regular basis to check for any changes. 
    
    I. YOUR CONSENT AND RIGHTS
    
    By using our service, making an application or visiting our website, you consent to the collection and use of your information and other activities as outlined in this policy.
    
    Under the Personal Data Protection Act (the “PDPA“), individuals have the right:
    to check whether we hold personal data about you and to access such data;
    to require us to correct as soon as reasonably practicable any data relating to you which is inaccurate;
    to ascertain our policies and practices in relation to personal data and the kind of personal data held by us; and
    to object to the use of your personal data for marketing purposes and we shall not use your personal data for marketing purposes after you communicate your objection to us.
    You may decide at any time to withdraw your consent to our use of your personal data in any manner or for a particular purpose. However, please note that any withdrawal of your consent may have consequences, including your inability to use any of our services. Please also note that notwithstanding the withdrawal of your consent, we reserve the right to retain your personal data for as long as is necessary for our legal or business purposes.
    You may exercise your opt-out right by notifying us if you wish to object to the use of your personal data for direct marketing purposes. Please send requests for such objections, access to data, correction of data, information regarding policies and practices and kinds of data held, questions or complaints to:
    Name: Chen Enjiao
    Address: 3 Fusionopolis Place, #02-51, Galaxis, Singapore 138523
    Email: codeforasia@gmail.com
    In accordance with the terms of the PDPA, we have the right to and may charge a minimum fee for processing any data access request.
     
    J. NO INFORMATION FROM CHILDREN UNDER 181 
    
    Code for Asia strongly believes in protecting the privacy of children. In line with this belief, we do not knowingly collect or maintain Personally Identifiable Information on our Site from persons under 18 years of age, and no part of our Site is directed to persons under 18 years of age. If you are under 18 years of age, then please do not send any personal data about yourself to us.
    
    K. GOVERNING LAW AND JURISDICTION 
    
    Nothing in this Privacy Policy shall limit the rights of the data subject under the PDPA. This Privacy Policy shall be governed by the laws of Singapore. You agree to submit to the exclusive jurisdiction of the Singapore courts. 
    
    L. CONTACT INFORMATION  
    
    Should you have a complaint or require more information about how we manage your personal data, please contact our headquarters at:
    
    Email: codeforasia@gmail.com
    
    FORMULATED ON: 24 MARCH 2016  
    
    LAST UPDATED ON: 30 NOVEMBER 2020 `,
    IMAGE_URL:'https://disk.mediaindonesia.com/files/news/2022/12/30/WhatsApp%20Image%202022-12-22%20at%2017.07.10%20(1).jpg'
}