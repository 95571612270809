import React, { useState } from 'react'
import Button from '../../../../shared_component/Button/Button'
import Client, { auth } from 'twitter-api-sdk';
import { donwloadContentInZIP } from '../../../../services/Donwload/DownloadContent';
import { getTwitterLongLiveToken } from '../../../../services/TwitterAPI/TwitterAPI';
import { getUserInfoFromLocalStorage } from '../../../../utils/localStorage';

export default function ModalTwitter({setSelectedTwitter, toggle}) {
    
    const [userInfo, setUserInfo] = useState(getUserInfoFromLocalStorage());
    const [connectedClick, setConnectedClick] = useState(false);
    
    const connectToTwitter = async() => {
        const url = "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=RjJXWkV1bjA2MW90VGhFZndWUy06MTpjaQ&redirect_uri=https://berlayar-ai-staging.web.app/redirected&scope=tweet.read%20users.read%20follows.read%20follows.write%20users.read%20tweet.write%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain";
        window.open(url,"blank");
        setConnectedClick(true);
    }

    const checkTwitterToken = () => {
      const newUserInfo = getUserInfoFromLocalStorage();
      if(newUserInfo.twitter_access_token !== undefined){
        setUserInfo(newUserInfo);
        setConnectedClick(false);
        setSelectedTwitter(true);
        toggle();
      }else{
        setConnectedClick(false);
      }
    }
  return (
    <div>
      {userInfo.twitter_access_token !== undefined ?(
        <p>Successfully connecting your Twitter Account</p>
      ):(
        <div>
        <p>
           <b>
             Its appears You Twitter Account is not connected with
             Berlayar.AI
           </b>
         </p>
         <p>
           Unfortunately, it appears your <b>Berlayar.ai</b> account is not
           connected with your <b>Twitter(X)</b> account. to publish content
           from berlayar.ai you need to connect your Twitter(X) account with
           berlayar.ai
         </p>
         <p>
         </p>
         <div
           style={{
             display: "flex",
             flexDirection: "row",
             justifyContent: "center",
           }}
         >
           {connectedClick ? (
             <Button text={'Verify Twitter Connection'} primary={true} onClick={()=>{checkTwitterToken()}}/>
           ):(
             <Button text={'Connect To Twitter'} primary={true} onClick={()=>{connectToTwitter()}}/>
           )}
           
         </div>
       </div>
      )}
       

    </div>
  )
}
