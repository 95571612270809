import React, { useEffect, useState } from "react";

import "./Generate.css";
import Button from "../../shared_component/Button/Button";
import Input from "../../shared_component/Input/Input";
import { getUserInfoFromLocalStorage } from "../../utils/localStorage";
import {
  buttonClickEvent,
  pageVisited,
} from "../../firebase/analytics/analytics";

import BerlayarModal from "../../shared_component/Modal/Modal";
import { Constant } from "../../Constant";
import ResultCard from "./ResultCard/ResultCard";
import GenerateDetailPage from "./GenerateDetailPage/GenerateDetailPage";
import GenerateEditPage from "./GenerateEditPage/GenerateEditPage";
import { generateContent } from "../../services/generateContentApi";
import GenerateUpload from "./GenerateUpload/GenerateUpload";
import SailingAnimation from '../../assets/icon/sailing.gif';
import ModalFreeUnlock from "./ModalFreeUnlock/ModalFreeUnlock";
import ModalTwitter from "./GenerateUpload/ModalTwitter/ModalTwitter";
import SelectDropDown from "../../shared_component/SelectDropDown/SelectDropDown";

function Generate() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [pageState, setPageState] = useState(0);
  const [pageList, setPageList] = useState(["generate"]);
  const [userInfo, setUserInfo] = useState(() => {
    return getUserInfoFromLocalStorage();
  });
  const [showModalUnlockFree, setShowModalUnlockFree] = useState(false);
  const [emptyAlert, setEmptyAlert] = useState("");

  useEffect(() => {
    addAnalytics("page");
    document.title = "Generate | Berlayar AI";
    
  }, []);

  const addAnalytics = (type) => {
    const userInfo = getUserInfoFromLocalStorage();
    if (userInfo) {
      if (type === "button") {
        const dataAnalitycs = {
          buttonName: "generate",
          uid: userInfo.uid,
          email: userInfo.email,
        };
        buttonClickEvent(dataAnalitycs);
      }
      if (type === "page") {
        const dataAnalitycs = {
          pageName: "generate",
          uid: userInfo.uid,
          email: userInfo.email,
        };
        pageVisited(dataAnalitycs);
      }
    }
  };

  const toggleModalUnlockFree = () => {
    const currentShowModalUnlockFree = showModalUnlockFree;
    setShowModalUnlockFree(!currentShowModalUnlockFree);
  }

  const handleDetailPageClick = (result) => {
    const currentPageList = pageList;
    const currentPageState = pageState;
    currentPageList.push("detail");
    setPageList(currentPageList);
    setPageState(currentPageState + 1);
    setResult(result);
  };

  const handleEditPageClick = (event,result) => {
    event.stopPropagation();
    const currentPageList = pageList;
    const currentPageState = pageState;
    currentPageList.push("edit");
    setPageList(currentPageList);
    setPageState(currentPageState + 1);
    setResult(result);
  };
  const handleUploadPageClick = (event, result) => {
    event.stopPropagation();
    const currentPageList = pageList;
    const currentPageState = pageState;
    currentPageList.push("upload");
    setPageList(currentPageList);
    setPageState(currentPageState + 1);
    setResult(result);
  };

  const handleBackClick = () => {
    const currentPageList = pageList;
    const currentPageState = pageState;
    currentPageList.pop();
    setPageList(currentPageList);
    setPageState(currentPageState - 1);
  };

  const handelGenerateButtonClicked = async () => {
    const resultArray = [];
    const newResult = {
                id:0,
                description:Constant.LOREM_IPSUM,
                imageUrl:Constant.IMAGE_URL,
                imagePrompt:"none",
                title:"LOREM IPSUM"
                
              };
    resultArray.push(newResult);  
    setResultList(resultArray)
    
    // if (userInfo === null) {
    //   toggleModalUnlockFree();
    // } else {
    //   if(prompt !== ""){
    //     setEmptyAlert("");
    //     setLoading(true);
    //     const resultArray = [];
    //     for (let i = 0; i < 4;i++){
    //       const generatedContent = await generateContent(
    //         prompt
    //       );
    //       if (generatedContent !== null) {
    //         const description = generatedContent.article.choices[0].text;
    //         const imageUrl = generatedContent.image_url.data[0].url;
    //         const imagePrompt = generatedContent.image_url.data[0].revised_prompt;
    //         const title = prompt;
    //         const newResult = {
    //           id:i,
    //           description,
    //           imageUrl,
    //           imagePrompt,
    //           title
              
    //         };
    //         resultArray.push(newResult)
    //         setResult(newResult);
    //       }
    //     }
    //     setResultList(resultArray);
    //     setLoading(false);
       
    //   }else{
    //     setEmptyAlert("Please Enter Your Topic First")
    //   }
      
    // }
  };


  const handleOnchangePrompt = (e) => {
    setPrompt(e.target.value);
  };

  return (
    <div className="generate-container">
      <BerlayarModal
        show={showModalUnlockFree}
        toggle={toggleModalUnlockFree}
        content={<ModalFreeUnlock/>}
      />
     
      {pageList[pageState] === "generate" ? (
        <>
          <div>
            <div style={{ display: "none" }}>
              <div>
                <p className="normal-text">Enter Prompt:</p>
                <div className="generate-box-container row">
                  <div className="col-8">
                    <div className="generate-box row">
                      <div className="col-4">
                        <p className="generate-box-text">
                          Generate post assets
                        </p>
                      </div>
                      <div className="col-8">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Topic Drowdown</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <Button
                      text={"Generate"}
                      primary={true}
                      padding={"15px 10px"}
                      width={"100%"}
                      onClick={handelGenerateButtonClicked}
                    />
                  </div>
                </div>
              </div>
              <div className="row generate-second-row">
                <div className="col-4">
                  <p className="normal-text">For:</p>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Audience</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-4">
                  <p className="normal-text">In a:</p>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Voice Tone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-4">
                  <p className="normal-text">To:</p>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Goal</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>

              <div className="generate-third-row">
                <p className="normal-text">In languages:</p>
                <div className="generate-checkbox-container">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      English
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Mandarin
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Malay
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Tamil
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="generate-input-prompt-container">
              <Input
                placeholder={"Enter Your Interesting Topic"}
                value={prompt}
                onChange={handleOnchangePrompt}
              />
              <div className="generate-divider"></div>
              <Button
                text={loading?"Generating..":"Generate"}
                primary={loading?false:true}
                width={200}
                onClick={loading?()=>{}: ()=>{handelGenerateButtonClicked()}}
              />
            </div>
            {emptyAlert !== "" && (
              <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <p className="empty-alert">{emptyAlert}</p>
              </div>
            )}
            
            {loading && (
              <div className="loading-container">
                <img src={SailingAnimation} alt="loading-animation" className="loading-animation"/>
                <p className="loading-text">Loading ...</p>
              </div>
            )}
            
            {resultList.length > 0 && (
              <div className="generate-four-row">
                <p className="normal-text">Result</p>
                {resultList.map((resultItem,index) => {
                  return(
                    <ResultCard
                    result={resultItem}
                    detailClick={handleDetailPageClick}
                    editClick={handleEditPageClick}
                    uploadClick={handleUploadPageClick}
                />
                  )
                })}
                
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div>
            <Button
              text={"Back"}
              primary={true}
              width={100}
              onClick={handleBackClick}
            />
            <div className="generate-main-divider"></div>

            <GenerateDetailPage
              result={result}
              editClick={(e)=>{handleEditPageClick(e,result)}}
              show={pageList[pageState] === "detail"}
              uploadClick={(e)=>{handleUploadPageClick(e,result)}}
            />

            <GenerateEditPage
              result={result}
              resultList={resultList}
              show={pageList[pageState] === "edit"}
              editClick={(e)=>{handleEditPageClick(e,result)}}
              setResult={setResult}
              setResultList={setResultList}
              uploadClick={(e)=>{handleUploadPageClick(e,result)}}
            />

            <GenerateUpload
              result={result}
              show={pageList[pageState] === "upload"}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Generate;
