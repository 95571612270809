import React, { useEffect, useState } from "react";

import { getUserInfoFromLocalStorage } from "../../../utils/localStorage";
import {
  getFacebookPageList,
  getLongLiveToken,
} from "../../../services/facebookAPI/facebookApi";
import SelectDropDown from "../../../shared_component/SelectDropDown/SelectDropDown";
import Button from "../../../shared_component/Button/Button";
import "./ModalFacebook.css";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { addingUser, updateUser } from "../../../firebase/database/user";
import FacebookLogin from "@greatsumini/react-facebook-login";

function ModalFacebook({ chooseFacebookPage, toggle }) {
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [userInfo, setUserInfo] = useState(() => {
    return getUserInfoFromLocalStorage();
  });
  const [options, setOptions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      checkTokenAvailable();
  }, []);

  const checkTokenAvailable = () => {
    if (userInfo.longLiveToken !== null) {
      getPageList();
    } else {
    }
  };

  const facebookButtonStyle = {
    borderRadius: 10,
    backgroundColor: "#F4444C",
    border: "none",
    width: 250,
    color: "#FFFFFF",
    fontFamily: "Outfit, sans-serif",
    fontSize: 18,
    paddingTop: 10,
    paddingBottom: 10,
  };

  const connectToFacebook = async (result) => {
    const facebookUID = result.userID;
    const accessToken = result.accessToken;

    const longLiveToken = await getLongLiveToken(accessToken);
    const newUserInfo = userInfo;
    newUserInfo.facebookUID = facebookUID;
    newUserInfo.longLiveToken = longLiveToken;
    await updateUser(newUserInfo);
    storeUserInfoToLocalStorage(newUserInfo);
    checkTokenAvailable();
  };

  const storeUserInfoToLocalStorage = (userInfo) => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  };

  const getPageList = async () => {
    const token = userInfo.longLiveToken;
    const uid = userInfo.facebookUID;
    const listPage = await getFacebookPageList(token, uid);
    const listPageData = listPage.data;
    if (listPageData.length > 0) {
      setIsAdmin(true);
      setPageList(listPageData);
      const optionList = getOptionsList(listPageData);
      setOptions(optionList);
      setIsLoading(false);
    } else {
      setIsAdmin(false);
      setIsLoading(false);
    }
  };

  const getOptionsList = (pageList) => {
    const optionsArray = [];
    if (pageList.length > 0) {
      for (let i = 0; i < pageList.length; i++) {
        const option = {
          image: "https://graph.facebook.com/" + pageList[i].id + "/picture",
          text: pageList[i].name,
          value: pageList[i].access_token,
        };
        optionsArray.push(option);
      }
      return optionsArray;
    } else {
      return [];
    }
  };

  const onPageListChange = (e) => {
    const value = e.target.value;
    const access_token = value.value;
    const listPage = pageList;
    const choosenPage = listPage.find(
      (item) => item.access_token === access_token
    );
    setSelectedPage(choosenPage);
  };

  const onSaveClick = () => {
    chooseFacebookPage(selectedPage);
    toggle();
  };
  return (
    <div>
      {userInfo.longLiveToken === null ? (
        <>
          <div>
            <p>
              <b>
                Its appears You Facebook Account is not connected with
                Berlayar.AI
              </b>
            </p>
            <p>
              Unfortunately, it appears your <b>Berlayar.ai</b> account is not
              connected with your <b>Facebook</b> account. to publish content
              from berlayar.ai you need to connect your facebook account with
              berlayar.ai
            </p>
            <p>
              <b>
                Before your connect your facebook account, make sure your
                facebook account is an admin of a facebook page. So you can have
                access to publish the content from Berlayar.ai to your facebook
                page
              </b>{" "}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                scope={
                  "pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement"
                }
                fields="accessToken"
                style={facebookButtonStyle}
                onSuccess={(response) => {
                  connectToFacebook(response);
                }}
                onFail={(error) => {
                  console.log("Login Failed!", error);
                }}
              >
                Connect to Facebook
              </FacebookLogin>
            </div>
          </div>
        </>
      ) : (
        <>
          {pageList.length > 0 ? (
            <>
              <div>
                <p>Choose Your Facebook Page</p>
                <SelectDropDown options={options} onChange={onPageListChange} />
              </div>
              <div className="modal-facebook-button-container">
                <Button
                  text={"Save"}
                  primary={true}
                  width={150}
                  onClick={onSaveClick}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <p>
                  <b>Its appears You are not an admin of any facebook page</b>
                </p>
                <p>
                  Unfortunately, it appears you are not currently an admin of
                  any Facebook pages. To publish content on a Facebook page, you
                  need to be an admin of that specific page
                </p>
                <p>
                  <b>Do you have a Facebook page?</b>
                </p>
                <p>
                  If you already have a Facebook page, please confirm that you
                  are the admin. If not, kindly request the current admin to add
                  your account as an admin. You can learn how to add an
                  administrator on the Facebook Page in{" "}
                  <a href="https://www.podium.com/article/how-to-add-administrator-to-facebook-page/">
                    Here
                  </a>
                </p>
                <p>
                  <b>Don't have a Facebook page yet?</b>
                </p>
                <p>
                  No worries! Creating a Facebook page is easy. Simply follow
                  the step-by-step guide on the Facebook Help Center:{" "}
                  <a href="https://www.facebook.com/business/help/1199464373557428?id=418112142508425">
                    Here
                  </a>
                  . Once you have created your page, you can then add yourself
                  as the admin and publish your content.
                </p>
                <p>
                  <b>
                    After you become an admin to a facebook page, you can
                    reconnect your facebook account to gain access for your page
                  </b>{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                    scope={
                      "pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_manage_engagement"
                    }
                    fields="accessToken"
                    style={facebookButtonStyle}
                    initParams={{
                      version:'v18.0'
                    }}
                    onSuccess={(response) => {
                      connectToFacebook(response);
                    }}
                    onFail={(error) => {
                      console.log("Login Failed!", error);
                    }}
                  >
                    Reconnect to Facebook
                  </FacebookLogin>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ModalFacebook;
