import React, { useEffect } from 'react'

import './AboutPage.css'
import AboutPageImage from '../../assets/images/about-image.png'
import Footer from '../../component/Footer/Footer'

function AboutPage() {

    useEffect(() => {
        document.title = "About | Berlayar AI";
    },[])

  return (
    <div>
        <div className='container-global'>
            <div>
                <h2 className='text-title'>What Berlayar.AI Can Do for You</h2>
            </div>
            <div>
                <h2 className='text-title'>Why Generative Agents</h2>
                <div className='row'>
                    <div className='col col-about'>
                        <div className='about-page-card'>
                            <p className='title-card-about'><b>Your Intruction is my immediate action</b></p>
                            <p className='subtitle-card-about'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                        <div className='about-page-card'>
                            <p className='title-card-about'><b>Your Intruction is my immediate action</b></p>
                            <p className='subtitle-card-about'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                        <div className='about-page-card'>
                            <p className='title-card-about'><b>Your Intruction is my immediate action</b></p>
                            <p className='subtitle-card-about'>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                    </div>
                    <div className='col col-about'>
                        <img src={AboutPageImage} alt='about-page' className='about-image'/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default AboutPage