import React, { useEffect, useState } from 'react'

import './AdminPage.css'
import SideBar from '../SideBar/SideBar'
import TopBar from '../TopBar/TopBar'
import { getUserInfoFromLocalStorage } from '../../utils/localStorage';

function AdminPage(props) {
  const [active, setActive] = useState(true);

  useEffect(()=> {
    const userInfos= getUserInfoFromLocalStorage();
    console.log("Makan")
    if(userInfos === null){
      window.location.href = "/"
    }
  },[])
  return (
    <div className='admin-page'>
      <div className='side-bar-container'>
        <SideBar page={props.page} active={active} setActive={setActive}/>
      </div>
        <div className='left-side'>
            <TopBar active={active} setActive={setActive}/>
            <div className='content'>
                {props.content}
            </div>
        </div>
        
    </div>
  )
}

export default AdminPage