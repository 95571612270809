import { doc, setDoc, getDoc, collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

export const addingUser = async (userInfo) => {
  await setDoc(doc(db, "user", userInfo.uid), userInfo);
};

export const updateUser =  async (userInfo) => {
    return new Promise(async(resolve, reject) => {
      const {uid} = userInfo;
      try{
        const userRef = doc(db,'user',uid);
        const docRef = await setDoc(userRef,userInfo,{merge:true});
        resolve(userInfo);
      }catch(e){
        reject(e)
      }
    })
    

}

export const getUserInfo = async (uid) => {
  const docRef = doc(db, "user", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const userInfo = docSnap.data();
    return userInfo;
  }
  return null;
};

export const addUserPreference = async (data) => {
  const { uid, category, value } = data;
  try {
    const preferencesRef = collection(db, "user", uid, "preferences");
    await addDoc(preferencesRef, {
      category,
      value,
    });
  } catch (error) {}
};
