import JSZip from 'jszip';

const donwloadContentInZIP = async (base64Image, caption) => {
    const zip = new JSZip();
    // Add the image to the ZIP file
    const imageFileName = 'image.jpg';
    const imageBlob = base64ToBlob(base64Image);
    zip.file(imageFileName, imageBlob, { binary: true });

    // Add the text file to the ZIP file
    const text = `Caption:\n${caption}`;
    const textFileName = 'berlayar_content.txt';
    zip.file(textFileName, text);

    // Generate the ZIP file
    zip.generateAsync({ type: 'blob' }).then((content) => {
      // Create a download link for the ZIP file
      const zipFileName = 'berlayar.zip';
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = zipFileName;

      // Trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};



const downloadCaption = (imageURL,caption) => {
    const text = 'Image URL : \n'+imageURL+'\n\n Caption: \n'+caption;
    const fileName =  'berlayar_content.txt';

    const element = document.createElement('a');
    const file = new Blob([text], {type:'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

const downloadImage = (base64Image) => {
  const blob = base64ToBlob(base64Image);
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'image.jpg');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const base64ToBlob = (rawBase64) => {
  const base64 = "data:image/jpeg;base64,"+rawBase64;
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};



export{donwloadContentInZIP, downloadCaption, downloadImage}