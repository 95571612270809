import React, { useEffect, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./TopBar.css";
import Input from "../../shared_component/Input/Input";
import IcDollar from "../../assets/icon/ic_dollar.png";
import IcNotification from "../../assets/icon/ic_notification.png";
import AvatarPlaceHolder from "../../assets/icon/avatar_placeholder.png";
import IcSettings from "../../assets/icon/ic_settings.png";
import IcAdmin from "../../assets/icon/ic_admin.png";
import IcLogout from "../../assets/icon/ic_logout.png";
import BerlayarModal from "../../shared_component/Modal/Modal";
import SettingsModal from "./SettingsModal/SettingsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function TopBar(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [modalSettings, setModalSettings] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getUserInfoFromLocalStorage();
  }, []);

  const getUserInfoFromLocalStorage = () => {
    const userInfos = localStorage.getItem("userInfo");
    setUserInfo(JSON.parse(userInfos));
  };

  const toggleModalSettings = () => {
    setModalSettings(!modalSettings);
  };

  const handleLogOut = () => {
    localStorage.removeItem("userInfo");
    navigate("/");
  };
  return (
    <div className="top-bar">
      <div className="top-bar-container">
        <BerlayarModal
          show={modalSettings}
          toggle={() => {
            toggleModalSettings();
          }}
          title={"Settings"}
          content={<SettingsModal></SettingsModal>}
        />

        <div className="top-bar-left">
          <Input placeholder={"Search"} />
        </div>
        <div className="top-bar-right">
          <FontAwesomeIcon icon={faBars} className="top-bar-right-item" onClick={()=>{props.setActive(!props.active)}}/>
          <img
            src={IcNotification}
            alt="icon notification"
            className="top-bar-right-item"
          />
          <img
            src={IcDollar}
            alt="icon dollars"
            className="top-bar-right-item"
          />
          <div
            className="profile top-bar-right-item"
            id="Popover1"
            type="button"
            onClick={toggle}
          >
            {userInfo != null ? (
              <>
                <img
                  src={
                    userInfo.photoURL != null
                      ? userInfo.photoURL
                      : AvatarPlaceHolder
                  }
                  alt="avatar-placeholder"
                  className="top-bar-avatar"
                />
              </>
            ) : (
              <>
                <img
                  src={AvatarPlaceHolder}
                  alt="avatar-placeholder"
                  className="top-bar-avatar"
                />
              </>
            )}

            {}
            <p>{userInfo !== null ? userInfo.name : "Jhone Doe"}</p>
            <Popover flip placement="bottom" target="Popover1" isOpen={open}>
              <PopoverBody>
                <div className="menu-popover-container">
                  <div
                    className="menu-popover-item"
                    onClick={() => {
                      toggleModalSettings();
                    }}
                  >
                    <img src={IcSettings} alt="icon-settings" />
                    <p>Settings</p>
                  </div>
                  <div className="menu-popover-item">
                    <img src={IcAdmin} alt="icon-settings" />
                    <p>Change to Admin</p>
                  </div>
                  <hr />
                  <div className="menu-popover-item" onClick={handleLogOut}>
                    <img src={IcLogout} alt="icon-settings" />
                    <p>Logout</p>
                  </div>
                </div>
              </PopoverBody>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
