import React, { useEffect } from 'react'

import './Redirect.css'
import { getTwitterLongLiveToken, getTwitterNewRefreshToken } from '../../services/TwitterAPI/TwitterAPI';
import { updateUser } from '../../firebase/database/user';
import { getUserInfoFromLocalStorage } from '../../utils/localStorage';
import Button from '../../shared_component/Button/Button';

export default function Redirect() {
   
  useEffect(()=> {
    const getTwitterToken = async() => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const longLiveToken = await getTwitterLongLiveToken(code);
      const data = longLiveToken.data;
      const access_token = data.access_token;
      const refresh_token = data.refresh_token;
      const userInfo = getUserInfoFromLocalStorage();
      userInfo.twitter_access_token = access_token;
      userInfo.twitter_refresh_token = refresh_token;
      await updateUser(userInfo);
      localStorage.setItem('userInfo',JSON.stringify(userInfo));
    }
    getTwitterToken();
  },[])


  return (
    <div
      className='redirected-main'
    >
      <div className='redirected-container'>
        <p className='redirect-text'>Your Twitter Account Successfully connected, You can close this tab</p>
      </div>
    </div>
  )
}
