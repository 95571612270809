import React, { useEffect, useState } from "react";

import "./SelectDropDown.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placeholder } from "reactstrap";

function SelectDropDown({ options, value, onChange, name,className,placeholder }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  const toggleShowOptions = () => {
    const currentShowOption = showOptions;
    setShowOptions(!currentShowOption);
  };

  const handleOptionClick = (index) => {
    const chooseOption = options[index];
    const data = {
      target: {
        value: chooseOption,
        name: name ? name : "",
      },
    };
    onChange(data);
    // if(value !== undefined){
    //     selectedOption(value)
    // }else{

    // }
    setSelectedOption(chooseOption);
    setActiveIndex(index);
    toggleShowOptions();
  };

  return (
    <div className={"select-drop-down-container"}>
      <div className={className?"select-drop-down-field "+className:"select-drop-down-field "} onClick={toggleShowOptions}>
        <div className="select-drop-down-field-left">
          <img
            src={
              selectedOption?.image !== undefined ? selectedOption.image : ""
            }
            alt="select-drop-down-field-icon"
            className="select-drop-down-field-icon"
            style={{
              display: selectedOption?.image !== undefined ? "flex" : "none",
            }}
          />

          <p className="select-drop-down-field-text">
            {selectedOption?.text !== undefined
              ? selectedOption.text
              : placeholder?placeholder: "Please Choose Option..."}
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={showOptions ? "dropdown-icon active" : "dropdown-icon"}
          />
        </div>
      </div>
      {showOptions && (
        <div className="select-drop-down-options-container">
          {options ? (
            options.length > 0 ? (
              options.map((option, index) => (
                <div
                  className={
                    activeIndex === index
                      ? "select-drop-down-option active"
                      : "select-drop-down-option"
                  }
                  onClick={() => {
                    handleOptionClick(index);
                  }}
                >
                  <img
                    style={{
                      display:
                        option && option.image !== undefined ? "flex" : "none",
                    }}
                    src={option && option.image ? option.image : ""}
                    alt="select-drop-down-field-icon"
                    className="select-drop-down-field-icon"
                  />
                  <p className="select-drop-down-field-text">{option.text}</p>
                </div>
              ))
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectDropDown;
