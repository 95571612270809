import React, { useEffect } from "react";

import "./Home.css";
import Woman from "../../assets/images/women.png";
import Man from "../../assets/images/man.png";
import Megaphone from "../../assets/images/megaphone.png";
import Database from "../../assets/images/database.png";
import Chat from "../../assets/images/chat.png";
import Arthur from "../../assets/images/arthur.png";
import Bertrand from "../../assets/images/bertrand.png";
import Kay from "../../assets/images/kay.png";
import ChingShi from "../../assets/images/chingshi.png";
import Footer from "../../component/Footer/Footer";
import Button from "../../shared_component/Button/Button";
import Generate from "../Generate/Generate";
import CheckBoxImage from '../../assets/images/checkbox.png'
import MouseClick from '../../assets/images/arrowclick.png';
import CircleAround from '../../assets/images/circle-around.png';
import ExclamationMark from '../../assets/images/exclamation.png';
import DiamondIcon from '../../assets/images/diamond.png';

function Home() {
  useEffect(() => {
    document.title = "Berlayar AI";
  }, []);
  return (
    <div>
      <div className="container-global">
        <div className="hero-container">
          <h1 className="hero-text">
            Create and Publish Hyperlocal Social Media Campaigns in{" "}
            <span className="red-text">Seconds</span>, Not Months.<img src={CheckBoxImage} alt="icon-checkbox" className="hero-checkbox"/>
          </h1>
          
        </div>
        <div className="second-section">
          <div className="col-start">
            <img src={Woman} alt="woman" />
          </div>
          <div className="col-middle">
            <div className="subtitle-container">
              <p className="subtitle">
                A full-suite of automated marketing agents at your disposal.
              </p>
              <br />
              <p className="small-subtitle">
                Find your nische and build an audience lean into topics that
                your audience care about.
              </p>
              <br />
              <p className="small-subtitle">
                Be the first to try <span className="red">Berlayar</span> when we roll out.
              </p>
              <br />
            </div>
            <div className="hero-email-container">
              <input
                type="email"
                placeholder="Enter Your Email Address"
                className="hero-input"
              />
              <Button
                text="SignUp"
                primary={true}
                width={"120px"}
                mobileWidth={"120px"}
              />
            </div>
          </div>
          <div className="col-end">
            <img src={Man} alt="man" />
          </div>
        </div>
      </div>
      <div className="video-section">
        <iframe
          width="100%"
          height="760px"
          src="https://www.youtube.com/embed/uMzUB89uSxU?si=laQmA77iI2ZKpmzS"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>

      <div className="container-global">
        <div className="third-section">
          <h2 className="third-section-title">
            What Berlayar.AI Can Do for You
          </h2>
          <div className="row">
            <div className="col">
              <div>
                <img
                  src={Megaphone}
                  alt="megaphone"
                  className="third-section-image"
                />
                <p>
                  <b>Listen</b> to what people are saying online and discover
                  new audiences or emerging trens
                </p>
              </div>
            </div>
            <div className="col">
              <div>
                <img
                  src={Database}
                  alt="database"
                  className="third-section-image"
                />
                <p>
                  <b>Create</b> platform-friendly content complete with copy,
                  and images generated from your dataset
                </p>
              </div>
            </div>
            <div className="col">
              <div>
                <img src={Chat} alt="chat" className="third-section-image" />
                <p>
                  <b>Capture</b> mindshare and zeitgeist to accelerate bussiness
                  growth
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-global">
        <div className="fourth-section">
          <h1 className="hero-text">
          <img src={ExclamationMark} alt="mouse-icon" className="exclamation-icon"/>
            It's like having a multinational team of experts <img src={DiamondIcon} alt="mouse-icon" className="diamond-icon"/>
            <br/> 
            <img src={CircleAround} alt="mouse-icon" className="circle-around-icon"/>
            that can work for
            you 24/7<img src={MouseClick} alt="mouse-icon" className="cursor-icon"/>
          </h1>
          
          <p className="third-section-title" style={{ marginTop: "180px" }}>
            Generate Now for Free!
          </p>
          <Generate/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
