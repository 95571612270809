import React from "react";

import "./Pricing.css";
import PricingCard from "../../component/PricingCard/PricingCard";
import Footer from "../../component/Footer/Footer";

function Pricing() {
  const feature = [
    { feature: "Lorem Ipsum is simply dummy text" },
    { feature: "Lorem Ipsum is simply dummy text" },
    { feature: "Lorem Ipsum is simply dummy text" },
    { feature: "Lorem Ipsum is simply dummy text" },
  ];
  return (
    <div>
      <div className="global-container">
        <div className="content-row">
          <h2 className="pricing-content-title">All You Need in One Place.</h2>
          <div className="pricing-list-container">
            <div className="pricing-items">
              <PricingCard
                price={"Free"}
                highlight={"Limited usage for teams"}
                planName={"Free"}
                feature={feature}
                space={true}
              />
            </div>
            <div className="pricing-items">
              <PricingCard
                price={"S$0"}
                highlight={"Per user / month Billed anually"}
                planName={"Commercial"}
                feature={feature}
                bestOffer={true}
                space={true}
              />
            </div>
            <div className="pricing-items">
              <PricingCard
                price={"S$0"}
                highlight={"Per user / month Billed anually"}
                planName={"Bussiness"}
                feature={feature}
                space={true}
              />
            </div>
          </div>
        </div>
        <div className="content-row">
          <h2 className="pricing-content-title">Plan comparison</h2>
          <div className="row">
            <div className="col-6">
              <p className="comparison-header">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-header">Free</p>
                </div>
                <div className="col-4">
                  <p className="comparison-header">Commercial</p>
                </div>
                <div className="col-4">
                  <p className="comparison-header">Business</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <p className="comparison-item">Features</p>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-4">
                  <p className="comparison-item-icon">X</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
                <div className="col-4">
                  <p className="comparison-item-icon">O</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-row">
          <div className="pricing-list-container">
            <div className="pricing-items">
              <PricingCard
                price={"Free"}
                highlight={"Limited usage for teams"}
              />
            </div>
            <div className="pricing-items">
              <PricingCard
                price={"S$0"}
                highlight={"Per user / month Billed anually"}
              />
            </div>
            <div className="pricing-items">
              <PricingCard
                price={"S$0"}
                highlight={"Per user / month Billed anually"}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricing;
