import React from 'react'

import './ModalFreeUnlock.css'
import Button from '../../../shared_component/Button/Button'
import { Link } from 'react-router-dom'

function ModalFreeUnlock() {
  return (
    <div className='modal-free-unlock-container'>
        <p className='modal-free-unlock-title'><b>Please Sign Up to Unlock Free Version</b></p>
        <p className='modal-free-unlock-subtitle'>Start creating captivating social media content on <span style={{color:'#F4444C'}}>Berlayar.ai's</span> free version! Sign up or log in with your account now. It's quick, easy, and completely free. Click the button below to dive in!</p>
        <Link to={'/get-started'} style={{textDecoration:'none'}}>
            <Button text={'Get Started'} primary={true} width={150}/>
        </Link>
        
    </div>
  )
}

export default ModalFreeUnlock