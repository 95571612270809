import React from "react";

import "./PricingCard.css";
import Button from "../../shared_component/Button/Button";

function PricingCard(props) {
  return (
    <div>
      <div className="price-card">
        {props.space ? <div className="image-space"></div> : <></>}

        <div className="price-row-one">
          <p className="title-card-text">{props.price}</p>
          <p className="highlight-card-text">{props.highlight}</p>
        </div>
        <div>
          <p className="subtitle-card-text">{props.planName}</p>
        </div>
        <div>
          {props.feature ? (
            <>
              {props.feature.map((item, index) => {
                return (
                  <p className="card-feature-text">
                    Lorem Ipsum is simply dummy text
                  </p>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <Button text={"sign up"} primary={true} width={"200px"} />
        </div>
      </div>
      {props.bestOffer ? (
        <div className={"best-offer-container"}>
          <p className="best-offer-text">Best Offer!</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PricingCard;
