import React from "react";
import Button from "../../../shared_component/Button/Button";
import { imageUrlIntoBase64 } from "../../../services/TwitterAPI/TwitterAPI";
import { donwloadContentInZIP } from "../../../services/Donwload/DownloadContent";

function ResultCard({ result, detailClick, editClick, uploadClick }) {

  const handleDownload = async() => {
    imageUrlIntoBase64(result.imageUrl).then((resultBase64) => {
      if(resultBase64.success){
        const base64 = resultBase64.data;
        donwloadContentInZIP(base64,result.description);
      }
    })
  };
  return (
    <div className="result-card" onClick={()=>{detailClick(result)}}>
      <img src={result.imageUrl} alt="result" />
      <div className="result-content">
        <p className="normal-text">
          <b>{result.title}</b>
        </p>
        <p className="normal-text">{result.description}</p>
        <div className="generate-button-container">
          <Button
            text={"Edit"}
            primary={true}
            width={150}
            onClick={(e)=>{editClick(e,result)}}
          />
          <div className="generate-space"></div>
          <Button text={"Download"} width={150} onClick={() => {handleDownload()}} />
          <div className="generate-space"></div>
          <Button text={"Upload"} width={150} onClick={(e)=>{uploadClick(e,result)}} />
        </div>
      </div>
    </div>
  );
}

export default ResultCard;
