import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./pages/Home/Home";
import NavBar from "./component/NavBar/NavBar";
import GetStarted from "./pages/GetStarted/GetStarted";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Dashboard from "./pages/Dashboard/Dashboard";
import AdminPage from "./component/AdminPage/AdminPage";
import Generate from "./pages/Generate/Generate";
import Search from "./pages/Search/Search";
import Storage from "./pages/Storage/Storage";
import Chat from "./pages/Chat/Chat";
import AboutPage from "./pages/AboutPage/AboutPage";
import Pricing from "./pages/Pricing/Pricing";
import Redirect from "./pages/RedirectTwitter/Redirect";

function App() {


  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<NavBar content={<Home />} />} />
      </Routes>
      <Routes>
        <Route exact path="/get-started" element={<GetStarted />} />
      </Routes>
      <Routes>
        <Route exact path="/redirected" element={<Redirect />} />
      </Routes>
      <Routes>
        <Route exact path="/login" element={<Login />} />
      </Routes>
      <Routes>
        <Route exact path="/sign-up" element={<SignUp />} />
      </Routes>
      <Routes>
        <Route
          exact
          path="/dashboard"
          element={<AdminPage content={<Dashboard />} page={"dashboard"} />}
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/generate"
          element={<AdminPage content={<Generate />} page={"generate"} />}
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/search"
          element={<AdminPage content={<Search />} page={"search"} />}
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/storage"
          element={<AdminPage content={<Storage />} page={"storage"} />}
        />
      </Routes>
      <Routes>
        <Route exact path="/chat" element={<Chat />} />
      </Routes>
      <Routes>
        <Route
          exact
          path="/about"
          element={<NavBar content={<AboutPage />} />}
        />
      </Routes>
      <Routes>
        <Route
          exact
          path="/pricing"
          element={<NavBar content={<Pricing />} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
