import React, { useEffect, useState } from 'react'

import './Chat.css'
import Sidebar from './SideBar/Sidebar'
import TopBar from './TopBar/TopBar'
import IconReload from '../../assets/icon/ic_reload_white.png'
import IconPlus from '../../assets/icon/ic_plus_black.png'
import IconQuestion from '../../assets/icon/ic_question_white.png'
import ChatCard from './ChatCard/ChatCard'
import { getUserInfoFromLocalStorage } from '../../utils/localStorage'
import { pageVisited } from '../../firebase/analytics/analytics'

function Chat() {
  const [chatList,setChatList] = useState([
    {id:1,
      title:'Marketing Strategy', 
    chat:[
      {content:'welcome',ai:true},
      {content:'lorem ipsum',ai:false},
      {content:'lorem ipsum',ai:true},
      {content:'lorem ipsum',ai:true},
    ]},
    {id:2,
      title:'Social Media Data', 
    chat:[
      {content:'lorem ipsum',ai:true},
      {content:'lorem ipsum',ai:false},
      {content:'lorem ipsum',ai:true},
      {content:'lorem ipsum',ai:true},
    ]},
    {id:3,
      title:'Data Analyst', 
    chat:[
      {content:'lorem ipsum',ai:true},
      {content:'lorem ipsum',ai:false},
      {content:'lorem ipsum',ai:true},
      {content:'lorem ipsum',ai:true},
    ]}
  ]);

  const [selectedChat,setSelectedChat] = useState(chatList[0]);
  const [input, setInput] = useState(); 

  useEffect(() => {
    addAnalytics();
    document.title = "Chat | Berlayar AI";
  },[])

  const addAnalytics = () => {
    const userInfo = getUserInfoFromLocalStorage();
    if(userInfo){
      const dataAnalitycs ={
        pageName:"chat",
        uid:userInfo.uid,
        email:userInfo.email
      }
      pageVisited(dataAnalitycs)
    }
  }

  const addNewChatItem = (contentItem,ai)=>{
    const newItem={content:contentItem, ai:ai}
    const updatedChatList = chatList.map(item => {
      if (item.id === selectedChat.id) {
        return {
          ...item,
          chat: [...item.chat, newItem] 
        };
      }
      return item;
    });
  
    setChatList(updatedChatList);
    const updatedSelectedChat = updatedChatList.find(item => item.id === selectedChat.id);
    setSelectedChat(updatedSelectedChat);
  }

  const handleKeyDown = (event) =>{
    if(event.key === 'Enter' && !event.shiftKey){
        addNewChatItem(input,false)
        setTimeout(() => {
          addNewChatItem("This is example respond from ai after you enter your prompt", true)
        }, 3000);
        setInput('')
      }
  }

  const handleOnChange = (event) => {
    setInput(event.target.value)
  }
  return (
    <div className='chat-page'>
      <Sidebar chatList={chatList.sort().reverse()} setSelect={setSelectedChat} addChat={setChatList} selectedChat={selectedChat}/>
      <div className='right-side'>
        <div className='top-side'>
          <TopBar />
        </div>
        <div className='chat-content'>
          <div className='chat-container'>
            {selectedChat.chat.map((chat, index) => {
              return(
                <ChatCard 
              content={
                  chat.content
              }
              ai={chat.ai}
            />
              )
            })}
          </div>
          <div className='chat-bottom'>
            <div className='icon-holder'>
              <img src={IconReload} alt='icon-reload' />
            </div>
            <div className='chat-input'>
              <div className='chat-input-container'>
                <textarea onKeyDown={handleKeyDown} value={input} onChange={handleOnChange}/>
                <div className='icon-holder-white' onClick={()=>{addNewChatItem()}}>
                  <img src={IconPlus} alt='icon-plus' />
                </div>
              </div>
            </div>
            <div className='icon-holder'>
              <img src={IconQuestion} alt='icon-question' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat