import React, { useEffect, useState } from "react";

import Modal from "../../shared_component/Modal/Modal";
import "./ModalUserPreference.css";
import { getUserInfoFromLocalStorage } from "../../utils/localStorage";
import { addUserPreference } from "../../firebase/database/user";
import { useNavigate } from "react-router-dom";

function ModalUserPreference(props) {
  const [listQuestion, setListQuestion] = useState([]);
  const [active, setActive] = useState(0);
  const [userPreference, setUserPreference] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const questions = props.userPreferenceQuestion;
    setListQuestion(questions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectClick = (selectedIndex) => {
    const current = active;
    const userInfo = getUserInfoFromLocalStorage();
    if (current < listQuestion.length - 1) {
      setActive(current + 1);
      const userPref = userPreference;
      const data = {
        category: listQuestion[current].category,
        value: listQuestion[current].options[selectedIndex],
        uid: userInfo.uid,
      };
      userPref.push(data);
      setUserPreference(userPref);
    } else {
      if (userPreference.length > 0) {
        userPreference.forEach((preference) => {
          addUserPreference(preference);
        });
        navigate("/generate");
      }
    }
  };
  return (
    <Modal
      show={props.show}
      content={
        <div className="get-started-modal">
          {listQuestion.length > 0 ? (
            <>
              <p className="get-started-modal-title">
                <b>{listQuestion[active].question}</b>{" "}
              </p>
              {listQuestion[active].options.length > 0 ? (
                <>
                  <div className="get-started-modal-select-container">
                    {listQuestion[active].options.map((options, index) => {
                      return (
                        <p
                          className="get-started-modal-button"
                          onClick={() => {
                            onSelectClick(index);
                          }}
                        >
                          {options}
                        </p>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <div className="get-started-modal-three-dot">
            {listQuestion.map((question, index) => {
              if (active === index) {
                return <span className="three-dot-active" />;
              } else {
                return <span />;
              }
            })}
          </div>
        </div>
      }
    ></Modal>
  );
}

export default ModalUserPreference;
