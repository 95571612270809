import React from 'react'

import './BottomTermPrivacy.css'

function BottomTermPrivacy() {
  return (
    <div className='term-container'>
        <p>Term & Conditions </p> <p> | </p> <p> Privacy Policy</p>
    </div>
  )
}

export default BottomTermPrivacy