import axios from "axios";

const baseURL = process.env.REACT_APP_FACEBOOK_API_URL;
const version = process.env.REACT_APP_FACEBOOK_API_VERSION;

const getLongLiveToken = async (token) => {
  const accessToken = token;
  const params = {
    params: {
      grant_type: "fb_exchange_token",
      client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
      client_secret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
      fb_exchange_token: accessToken,
    },
  };

  const result = await axios.get(
    baseURL + version + "/oauth/access_token",
    params
  );
  const data = result.data;
  const longLiveToken = data.access_token;
  if (longLiveToken) {
    return longLiveToken;
  } else {
    return null;
  }
};

const getFacebookPageList = async (token, userId) => {
  const params = {
    params: {
      access_token: token,
    },
  };
  const result = await axios.get(baseURL + userId + "/accounts", params);
  const data = result.data;
  if (data) {
    return data;
  } else {
    return null;
  }
};

const uploadToFacebook = async (value) => {
  const {
    access_token,
    message,
    url,
    pageId,
    published,
    scheduled_publish_time,
  } = value;
  const headers = {
    "Content-Type": "application/json",
  };
  let data = {};
  if (published) {
    data = {
      access_token,
      message,
      url,
    };
  } else {
    data = {
      access_token,
      message,
      url,
      published,
      scheduled_publish_time,
    };
  }

  const result = await axios.post(
    baseURL + version + "/" + pageId + "/photos",
    data,
    headers
  );
  const datas = result.data;
  if (datas) {
    return datas;
  } else {
    return null;
  }
};

export { getLongLiveToken, getFacebookPageList, uploadToFacebook };
